import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Form from '~ui/Form';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';

import {
  useToast, Heading, Text, CloseButton, Flex,
} from '@chakra-ui/react';
import translation from '../CreateTicket.translation';

const { t } = translation;

const propTypes = {
  onCreate: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  isCreateTicketTaskDone: PropTypes.bool.isRequired,
  isTicketCreated: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onTicketCreatedCallback: PropTypes.func.isRequired,
  isFeedbackModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

const defaultProps = {
  errors: [],
  isFeedbackModal: false,
  onCloseModal: null,
};

const CreateTicketScene = ({
  onCreate, onMount, onUnmount,
  isSaving,
  isCreateTicketTaskDone,
  isTicketCreated,
  errors,
  onTicketCreatedCallback,
  onCloseModal,
  isFeedbackModal,
}) => {
  const toast = useToast();

  const formFields = {

    subject: {
      type: 'text',
      label: t('formSubject'),
      placeholder: t('formSubjectPlaceholder'),
    },

    type: {
      type: 'select',
      label: t('formType'),
      options: [
        { value: 'change', label: 'Change' },
        { value: 'incident', label: 'Incident' },
        { value: 'service_window', label: 'Service Window' },
      ],
    },
    area: {
      type: 'select',
      label: t('formAreaName'),
      options: [
        { value: 'infrastructure', label: 'Infrastructure' },
        { value: 'security', label: 'Security' },
        { value: 'workplace', label: 'Workplace' },
        { value: 'database', label: 'Database' },
        { value: 'office 365', label: 'Office 365' },
        { value: 'other', label: 'Other' },
      ],
    },

    description: {
      type: 'textarea',
      label: t('formDescription'),
      placeholder: t('formDescriptionPlaceholder'),
    },
    file: {
      type: 'file',
      label: t('formFileLabel'),
    },
  };

  const formData = Form.useFormBuilder({
    ...(isFeedbackModal
      ? {
        description: formFields.description,
        file: formFields.file,
      }
      : {
        subject: formFields.subject,
        description: formFields.description,
        type: formFields.type,
        area: formFields.area,
        file: formFields.file,
      }),
  });

  useEffect(() => {
    if (!isSaving && isCreateTicketTaskDone) {
      if (errors.length) {
        if (!isTicketCreated) {
          toast({
            description: isFeedbackModal ? t('failFeedback') : t('failTicket'),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }

        errors.forEach((error) => {
          toast({
            description: t(error.message_translation_key),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      } else {
        toast({
          description: isFeedbackModal ? t('successFeedback') : t('successTicket'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        onTicketCreatedCallback();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateTicketTaskDone, isTicketCreated, isSaving, errors]);

  useEffect(() => {
    onMount();

    return () => onUnmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const title = isFeedbackModal ? t('feedbackTitle') : t('createTicketTitle');
  const submitText = isFeedbackModal ? t('send') : t('submit');
  return (

    <Block.Center style={{ padding: '1rem' }}>
      {onCloseModal && <Flex width="100%" flexDirection="row-reverse" marginBottom="-1rem"><CloseButton onClick={onCloseModal} position="relative" top="-1rem" right="-1rem" /></Flex>}
      <Heading as="h2" style={{ margin: '2rem' }}>
        {title}
      </Heading>
      {isFeedbackModal && <Text>{t('feedbackDescription')}</Text>}
      <Form.Primary
        form={formData}
        onSubmit={({ values }) => {
          onCreate({
            values,
          });
        }}
        style={{ maxWidth: '43.75rem' }}
      >
        {({ render, onSubmitAction }) => (
          <>
            {render}
            <Block.SpaceBetween>
              <Button
                isLoading={isSaving}
                disabled={isSaving}
                onClick={onSubmitAction}
              >
                {submitText}
              </Button>
            </Block.SpaceBetween>
          </>
        )}
      </Form.Primary>
    </Block.Center>

  );
};

CreateTicketScene.propTypes = propTypes;
CreateTicketScene.defaultProps = defaultProps;

export default CreateTicketScene;
